<template>
  <v-container>
    <v-card width="600" class="mx-auto pa-4" rounded="lg">
      <v-card-title>
        Add user to course
      </v-card-title>
      <v-card-text>
        <v-select
            :items="courses"
            v-model="courseId"
            item-value="id"
            item-text="title"
            label="Choose a course"
        ></v-select>
        <v-text-field v-model="phone" label="Phone number"/>
        <v-text-field v-model="amount" label="Amount"/>
        <v-text-field v-model="note" label="Note"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="addUser">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "AddToCourse",
  data() {
    return {
      courses: [],
      courseId: '',
      phone: '',
      amount: '',
      note: '',
    }
  },
  computed: {
    form() {
      let formData = new FormData
      formData.set('course_id', this.courseId)
      formData.set('phone', this.phone)
      formData.set('amount', this.amount)
      formData.set('note', this.note)
      return formData
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      const url = 'courses?filter=list'
      axios.get(url).then(response => {
        this.courses = response.data
      })
    },
    addUser() {
      const url = 'payment';
      axios.post(url, this.form).then(() => {
        Swal.fire(
            'Successful!',
        );
        this.clear()
      }).catch((error) => {
        console.log(error);
      })
    },
    clear() {
      this.phone = ''
      this.amount = ''
      this.note = ''
    }
  }
}
</script>

<style scoped>

</style>